<template>
<div class="h-screen flex w-full bg-img p-10">
    <div class="vx-col sm:w-full md:w-full lg:w-1/2 xl:w-1/4 mx-auto self-center">
        <vx-card>
            <div slot="no-body">
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-full mx-auto self-center d-theme-dark-bg">
                        <div class="p-8 mt-base">
                            <div class="vx-card__title mb-8">
                                <h1 class="mb-4">Boat Harbour Park </h1>
                            </div>
                            <div class="vx-card__title mb-8">
                                <h4 class="mb-4">Welcome ! {{ currentUser.name }} </h4>
                                <p>Complete Your Profile</p>
                            </div>
                            <div class="flex mb-4">
                                <div class="flex-1">
                                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                        <form @submit.prevent="handleSubmit(completeProfile)">
                                            <div class="mb-5">
                                                <ValidationProvider name="MobileNumber" :rules="{ regex: /^[0-9]+$/, required: true, max: 10 }" v-slot="{ errors }" :custom-messages="customMessages">
                                                    <vs-input label-placeholder="Mobile Number" placeholder="Mobile Number" v-model="updateUser.mobileNumber" class="w-full" />
                                                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="mb-base">
                                                <span class="text-dark text-sm">We will send latest information about your accounts, ballots and entry requirements for Boat Harbour Park</span>
                                            </div>

                                            <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row mt-20">
                                                <vs-button type="border" @click.prevent="cancel" class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto">Logout</vs-button>
                                                <vs-button class="w-full sm:w-auto" @click.prevent="completeProfile">Submit</vs-button>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</div>
</template>

<script>
import AuthService from "../../services/AuthService";
import {
    profileData
} from "../../store/api/profile"

const authservice = new AuthService();

import {
    customMessages,
    passwordValidationMessage
} from './../../filters/validationmessage'

export default {
    data() {
        return {
            currentUser: null,
            updateUser: null,
            customMessages,
            passwordValidationMessage
        }
    },
    async created() {
        await this.getUser();
    },
    methods: {
        async getUser() {
            this.currentUser = authservice.getUser();
            console.log('currentUser', this.currentUser);
            if (!this.currentUser) {
                this.$router.push("/login");
            }

            this.updateUser = {
                //emailAddress: this.currentUser.username
                azureObjectId: this.currentUser.localAccountId,
                firstname: this.currentUser.idTokenClaims.given_name,
                lastname: this.currentUser.idTokenClaims.family_name,
                emailAddress: this.currentUser.username,
            }
        },
        cancel() {
            this.$router.push("/logout");
        },
        async completeProfile() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            console.log('this.updateUser',this.updateUser);
            let result = await profileData.saveProfile(this.updateUser);
            if (!result.succeeded) {
                this.$vs.notify({
                    title: "Request Invalid",
                    text: result.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.$vs.notify({
                title: "Successful",
                text: "Registration Successful.",
                color: "success",
                position: "top-center"
            });

            this.$store.dispatch("completeLogin", this.currentUser);
        }
    }
}
</script>

<style scoped>
.custom-logo-size {
    height: 5rem;
}
</style>
